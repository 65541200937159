import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { PrimaryNavigation } from '../../PrimaryNavigation/PrimaryNavigation'
import { NavMenu } from '../../PrimaryNavigation/NavMenu'
import { NavItem } from '../../PrimaryNavigation/NavItem'
import { NavDropdownLinks } from '../../NavigationDropdown/NavDropdownLinks'
import { NavDropdownUtilityBar } from '../../NavigationDropdown/NavDropdownUtilityBar'
import { NavDropdownPanel } from '../../NavigationDropdown/NavDropdownPanel'
import { CreditUnionIcon, IconCodeType } from '../../icon/CreditUnionIcon'
import { MobileMenu } from '../../MobileMenu/MobileMenu'
import { StyledMobileMenuUtility } from '../../MobileMenu/styledMobileMenuUtility'
import {
  MobileMenuBackItem,
  MobileMenuHeader,
  MobileMenuLinkItem,
  MobileMenuUtilityItem,
} from '../../MobileMenu/MobileMenuItem'
import { Grid, Link } from '@material-ui/core'
import {
  StyledOverviewChevron,
  StyledOverviewContainer,
  StyledOverviewTitle,
  StyledHeaderContainer,
} from '../../NavigationDropdown/styledNavDropdownUtilityBar'
import { CircleIcon } from '../../NavigationDropdown/circleIcon'
import { useRouter } from 'next/router'
import { useTheme, useMediaQuery } from '@material-ui/core'
import { isNotNull } from '../../../utils/nonNull'
import { HeaderGlobals } from '../../../globals/context'

export type HeaderProps = HeaderGlobals

const ICON_GROUP_TYPE = 'modules\\megaMenu\\nodes\\IconGroup' as const

export const HeaderFragment: React.VFC<HeaderProps> = ({ navigation, siteInfo }) => {
  const [open, setOpen] = useState(false)
  const router = useRouter()
  const secondary = useMemo(() => router.pathname !== '/', [router])
  const toggleMenu = useCallback(() => setOpen(!open), [setOpen, open])
  const closeMenu = useCallback(() => setOpen(false), [setOpen])
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  let idx = 0
  const { siteNumberBsb, siteNumberPhone, siteLoginLink } = siteInfo

  useEffect(() => {
    if (!isMobile && open) {
      toggleMenu()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, open])

  return (
    <StyledHeaderContainer maxWidth="xl">
      <PrimaryNavigation BSB={siteNumberBsb??''} LoginLink={siteLoginLink??'#'} secondary={secondary} openMobileMenu={toggleMenu}>
        <NavMenu>
          {navigation.nodes.map((node, i) => {
            const menus = node.children?.filter((node) => node.type !== ICON_GROUP_TYPE) ?? []
            const iconGroup = node.children?.find((node) => node.type === ICON_GROUP_TYPE) ?? undefined

            return (
              <NavItem
                key={i}
                title={node.title}
                aria-haspopup={node.children ? 'true' : 'false'}
                href={node.children && node.children.length > 0 ? '#' : node.url}
              >
                {menus.length > 0 ? (
                  <NavDropdownPanel title={node.title}>
                    <Grid item>
                      <NavDropdownLinks container fullWidth>
                        {
                          menus.map((node, i) => (
                            <Grid sm={6} md={3} item key={i}>
                              <NavDropdownLinks card title={node.title} href={node.url || '#'}>
                                {node.children?.map((node, i) => (
                                  <NavDropdownLinks item key={i} href={node.url}>
                                    {node.title}
                                  </NavDropdownLinks>
                                ))}
                              </NavDropdownLinks>
                            </Grid>
                          ))
                        }
                        <Grid key={i} item sm={6} md={3}
                          container
                          direction="column"
                          justifyContent={iconGroup ? 'flex-start' : 'flex-end'}>
                          {iconGroup && (
                            <NavDropdownUtilityBar
                              title={iconGroup.title}
                              href={iconGroup.url || '#'}
                              justifyContent="flex-start"
                              alignItems="center">
                              {iconGroup.children?.map((node, i) => (
                                <NavDropdownUtilityBar card
                                  key={i}
                                  title={node.title}
                                  iconCode={node.data.icon as IconCodeType}
                                  href={node.url || '#'}
                                />
                              ))}
                            </NavDropdownUtilityBar>
                          )}
                          <StyledOverviewContainer>
                            {/* @ts-ignore */}
                            {isNotNull(node.url) && node.url !='' && <Link href={node.url} passHref>
                              <StyledOverviewTitle tabIndex={0}>
                                {node.title} overview page
                              </StyledOverviewTitle>
                              <StyledOverviewChevron>
                                <CircleIcon />
                              </StyledOverviewChevron>
                            </Link>}
                          </StyledOverviewContainer>
                        </Grid>
                      </NavDropdownLinks>
                    </Grid>
                  </NavDropdownPanel>
                ) : null}
              </NavItem>
            )
          })}
        </NavMenu>
      </PrimaryNavigation>
      <MobileMenu wrapper portal={'#mobile-menu'} isMenuOpen={open}>
        <MobileMenu navigation level={0} idx={idx++}>
          <MobileMenuHeader phoneNumber={siteNumberPhone?.url??''} bsbNumber={siteNumberBsb??''} onClose={closeMenu} />
          {navigation.nodes.map((node, i) => {
            const menus = node.children?.filter((node) => node.type !== ICON_GROUP_TYPE) || []
            const iconGroup = node.children?.find((node) => node.type === ICON_GROUP_TYPE) ?? undefined
            const title = `${node.title} overview`

            return (
              <MobileMenu item key={i} name={node.title} level={0} idx={idx++} hasChildren={menus.length > 0}>
                <MobileMenu navigation level={1} idx={idx}>
                  <MobileMenuBackItem level={1} idx={idx}>Back</MobileMenuBackItem>
                  <MobileMenuLinkItem href={node.url || '#'} isTitle>
                    {title} page
                    {menus.length > 0 && (
                      <StyledOverviewChevron>
                        <CircleIcon />
                      </StyledOverviewChevron>
                    )}
                  </MobileMenuLinkItem>
                  {menus.length > 0 && menus.map((node, i) => (
                    <MobileMenu key={i} item name={node.title} level={1} idx={idx++} hasChildren={Boolean(node.children && node.children.length > 0)}>
                      <MobileMenu navigation level={2} idx={idx}>
                        <MobileMenuBackItem level={2} idx={idx}>
                          {title}
                        </MobileMenuBackItem>
                        <MobileMenuLinkItem href={node.url || '#'} isTitle>
                          {node.title}
                          <StyledOverviewChevron>
                            <CircleIcon />
                          </StyledOverviewChevron>
                        </MobileMenuLinkItem>
                        {node.children && node.children.map((node, i) => (
                          <MobileMenuLinkItem key={i} href={node.url || '#'}>
                            {node.title}
                          </MobileMenuLinkItem>
                        ))}
                      </MobileMenu>
                    </MobileMenu>
                  ))}
                  {iconGroup && iconGroup.children && (
                    <StyledMobileMenuUtility>
                      {iconGroup.children.map((node, i) => (
                        <MobileMenuUtilityItem key={i} href={node.url || '#'} icon={<CreditUnionIcon iconCode={node.data.icon as IconCodeType} />}>
                          {node.title}
                        </MobileMenuUtilityItem>
                      ))}
                    </StyledMobileMenuUtility>
                  )}
                </MobileMenu>
              </MobileMenu>
            )
          })}
        </MobileMenu>
      </MobileMenu>
    </StyledHeaderContainer>
  )
}